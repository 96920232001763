import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import BlogPostCard1 from '../components/blog-post-card1'
import Invitation from '../components/invitation'
import CandidatesLarge from '../components/candidates-large'
import CandidatesSmall from '../components/candidates-small'
import OneShot from '../components/one-shot'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Za Lepší Slatiňany</title>
        <meta property="og:title" content="Za Lepší Slatiňany" />
      </Helmet>
      <Header></Header>
      <div className="home-hero">
        <div className="home-container1">
          <h1 className="home-text">
            <span>Volby do Zastupitelstva města Slatiňany</span>
            <br></br>
          </h1>
          <span className="home-text03">
            <span>23. - 24. září 2022</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <span className="home-text07">Volte č. 6</span>
          <Link to="/program" className="home-navlink button">
            Volební program
          </Link>
        </div>
      </div>
      <h2 className="home-text08">
        <span>Napsali jsme</span>
        <br></br>
      </h2>
      <div className="home-blog">
        <div className="home-container2">
          <Link
            to="/clankyrozpoctove-odpovedne-hospodareni"
            className="home-navlink1"
          >
            <BlogPostCard1
              text="sd"
              title="Rozpočtově odpovědné hospodaření"
              author="Ing. Marie Herrová"
              position="členka ﬁnančního výboru"
              description="Za uplynulé 4 roky našeho působení v Zastupitelstvu a Radě města jsme v rámci koalice a našich možností prosazovali efektivní hospodaření s penězi daňových poplatníků doprovázené mnohdy provozními škrty. Zároveň jsme bránili navyšování počtu zaměstnanců úřadu. I přes výpadky příjmů ze sdílených daní způsobené pandemií byl ve všech letech dosažen kladný hospodářský výsledek a vytvořeny zdroje pro další investice. Výsledek hospodaření je znázorněn v uvedeném grafu. Za celé volební období město hospodařilo bez nového úvěru či půjčky a veškeré investice financovalo z rozpočtu města a obdržených dotací."
              profile_src="http://blur.lepsislatinany.cz/image/herrova.png"
              rootClassName="rootClassName3"
              className="home-component1"
            ></BlogPostCard1>
          </Link>
        </div>
        <div className="home-container3">
          <Link to="/clankyudrzba-a-vzhed-mesta" className="home-navlink2">
            <BlogPostCard1
              title="Údržba a vzhled města"
              author="Marie Málková"
              position="radní"
              image_src="https://images.unsplash.com/photo-1465925508512-1e7052bb62e6?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxjaXR5JTIwY2FifGVufDB8fHx8MTYyNjQ1MDMwNA&amp;ixlib=rb-1.2.1&amp;h=1200"
              description="V roce 2018 jsme Vám ve volebním programu předložili naši představu, jak chceme pro město pracovat a co všechno bychom ve Slatiňanech chtěli zlepšit, či změnit.  Jedním z našich několika stěžejních bodů byla údržba a vzhled města. Bylo a je naší snahou postarat se o některé základní a zásadní změny. Jsme totiž přesvědčeni, že vedle nejrůznějších služeb zdravotních, sociálních, či sítě obchodů, pohostinství a občerstvení, které v našem městě poskytují místní podnikatelé, je pro spokojený život občanů velmi důležitý také vzhled města, údržba, pořádek a čistota. Nakonec o tom svědčí i řada Vašich připomínek a stížností. Podařilo se nám obnovit a květinami trochu oživit park před základní školou"
              profile_src="http://blur.lepsislatinany.cz/image/DSCF0318.png"
              rootClassName="rootClassName2"
              className="home-component2"
            ></BlogPostCard1>
          </Link>
        </div>
        <div className="home-container4">
          <Link
            to="/clankyjaky-bude-poplatek-za-odpady-pristi-rok"
            className="home-navlink3"
          >
            <BlogPostCard1
              title="Jaký bude poplatek za odpady příští rok?"
              author="Ing. Vít Steklý"
              position="radní"
              image_src="https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE4fHxjaXR5fGVufDB8fHx8MTYyNjQ1MDI4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
              description="Po našem nástupu na radnici nás v roce 2018 čekal nelehký úkol, jak dál posunout odpadové hospodářství a vypořádat se se stále přísnější legislativou v souvislosti se skládkováním směsného odpadu. V té době se bohužel sešel souběh konce tří smluv, které zajišťovaly svoz komunálního odpadu, výsyp tříděných kontejnerů a provoz sběrného dvora. Zároveň jsme byli upozorněni krajským úřadem, že uvedené služby musíme soutěžit jako jednu zakázku oproti minulosti, což nás posunulo do nadlimitního režimu dle zákona. Taková forma veřejné soutěže je administrativně daleko náročnější, a tak bylo rozhodnuto o výběru firmy, ..."
              profile_src="http://blur.lepsislatinany.cz/image/steklys.png"
              rootClassName="rootClassName1"
              className="home-component3"
            ></BlogPostCard1>
          </Link>
        </div>
      </div>
      <Link to="/clanky" className="home-navlink4 button">
        Zobrazit články
      </Link>
      <Invitation></Invitation>
      <h2 className="home-text11">
        <span>Naši kandidáti</span>
        <br></br>
      </h2>
      <CandidatesLarge></CandidatesLarge>
      <CandidatesSmall rootClassName="candidates-small-root-class-name"></CandidatesSmall>
      <Link to="/kandidati" className="home-navlink5 button">
        Zobrazit všechny kandidáty
      </Link>
      <OneShot rootClassName="one-shot-root-class-name"></OneShot>
      <Footer></Footer>
    </div>
  )
}

export default Home
