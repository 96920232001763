import React from 'react'

import PropTypes from 'prop-types'

import './invitation.css'

const Invitation = (props) => {
  return (
    <div className={`invitation-invitation ${props.rootClassName} `}>
      <div className="invitation-container">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="invitation-image"
        />
        <img
          alt={props.image_alt1}
          src={props.image_src1}
          className="invitation-image1"
        />
      </div>
      <span className="invitation-text">{props.text}</span>
      <span className="invitation-text1">{props.text1}</span>
      <span className="invitation-text2">
        <span className="">Volte č. 6</span>
        <br className=""></br>
      </span>
    </div>
  )
}

Invitation.defaultProps = {
  text1: 'Volby do Zastupitelstva města Slatiňany • 23. - 24. září 2022',
  image_src: 'http://blur.lepsislatinany.cz/imageu/odslogo.png',
  text: 'Přijďte k volbám a pomozte nám dokončit změnu.',
  rootClassName: '',
  image_alt: 'image',
  image_src1: 'http://blur.lepsislatinany.cz/imageu/toplogo.png',
  image_alt1: 'image',
}

Invitation.propTypes = {
  text1: PropTypes.string,
  image_src: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt1: PropTypes.string,
}

export default Invitation
