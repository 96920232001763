import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import BlogPostCard1 from '../components/blog-post-card1'
import Footer from '../components/footer'
import './lnky.css'

const Lnky = (props) => {
  return (
    <div className="lnky-container">
      <Helmet>
        <title>lnky - Za Lepší Slatiňany</title>
        <meta property="og:title" content="lnky - Za Lepší Slatiňany" />
      </Helmet>
      <Header rootClassName="header-root-class-name2"></Header>
      <div className="lnky-breadcumbs">
        <Link to="/" className="lnky-navlink">
          <svg viewBox="0 0 1024 1024" className="lnky-icon">
            <path d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"></path>
          </svg>
        </Link>
        <svg viewBox="0 0 1024 1024" className="lnky-icon2">
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link to="/clanky" className="lnky-navlink1">
          <span>Články</span>
          <br></br>
        </Link>
      </div>
      <h1 className="lnky-text2">
        <span>Články</span>
        <br></br>
      </h1>
      <div className="lnky-blog">
        <div className="lnky-container1">
          <Link
            to="/clankyrozpoctove-odpovedne-hospodareni"
            className="lnky-navlink2"
          >
            <BlogPostCard1
              text="sd"
              title="Rozpočtově odpovědné hospodaření"
              author="Ing. Marie Herrová"
              position="členka ﬁnančního výboru"
              description="Za uplynulé 4 roky našeho působení v Zastupitelstvu a Radě města jsme v rámci koalice a našich možností prosazovali efektivní hospodaření s penězi daňových poplatníků doprovázené mnohdy provozními škrty. Zároveň jsme bránili navyšování počtu zaměstnanců úřadu. I přes výpadky příjmů ze sdílených daní způsobené pandemií byl ve všech letech dosažen kladný hospodářský výsledek a vytvořeny zdroje pro další investice. Výsledek hospodaření je znázorněn v uvedeném grafu. Za celé volební období město hospodařilo bez nového úvěru či půjčky a veškeré investice financovalo z rozpočtu města a obdržených dotací."
              profile_src="http://blur.lepsislatinany.cz/image/herrova.png"
              rootClassName="blog-post-card1-root-class-name8"
              className="lnky-component1"
            ></BlogPostCard1>
          </Link>
        </div>
        <div className="lnky-container2">
          <Link to="/clankyudrzba-a-vzhed-mesta" className="lnky-navlink3">
            <BlogPostCard1
              title="Údržba a vzhled města"
              author="Marie Málková"
              position="radní"
              image_src="https://images.unsplash.com/photo-1465925508512-1e7052bb62e6?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxjaXR5JTIwY2FifGVufDB8fHx8MTYyNjQ1MDMwNA&amp;ixlib=rb-1.2.1&amp;h=1200"
              description="V roce 2018 jsme Vám ve volebním programu předložili naši představu, jak chceme pro město pracovat a co všechno bychom ve Slatiňanech chtěli zlepšit, či změnit.  Jedním z našich několika stěžejních bodů byla údržba a vzhled města. Bylo a je naší snahou postarat se o některé základní a zásadní změny. Jsme totiž přesvědčeni, že vedle nejrůznějších služeb zdravotních, sociálních, či sítě obchodů, pohostinství a občerstvení, které v našem městě poskytují místní podnikatelé, je pro spokojený život občanů velmi důležitý také vzhled města, údržba, pořádek a čistota. Nakonec o tom svědčí i řada Vašich připomínek a stížností. Podařilo se nám obnovit a květinami trochu oživit park před základní školou"
              profile_src="http://blur.lepsislatinany.cz/image/DSCF0318.png"
              rootClassName="blog-post-card1-root-class-name9"
              className="lnky-component2"
            ></BlogPostCard1>
          </Link>
        </div>
        <div className="lnky-container3">
          <Link
            to="/clankyjaky-bude-poplatek-za-odpady-pristi-rok"
            className="lnky-navlink4"
          >
            <BlogPostCard1
              title="Jaký bude poplatek za odpady příští rok?"
              author="Ing. Vít Steklý"
              position="radní"
              image_src="https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE4fHxjaXR5fGVufDB8fHx8MTYyNjQ1MDI4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
              description="Po našem nástupu na radnici nás v roce 2018 čekal nelehký úkol, jak dál posunout odpadové hospodářství a vypořádat se se stále přísnější legislativou v souvislosti se skládkováním směsného odpadu. V té době se bohužel sešel souběh konce tří smluv, které zajišťovaly svoz komunálního odpadu, výsyp tříděných kontejnerů a provoz sběrného dvora. Zároveň jsme byli upozorněni krajským úřadem, že uvedené služby musíme soutěžit jako jednu zakázku oproti minulosti, což nás posunulo do nadlimitního režimu dle zákona. Taková forma veřejné soutěže je administrativně daleko náročnější, a tak bylo rozhodnuto o výběru firmy, ..."
              profile_src="http://blur.lepsislatinany.cz/image/steklys.png"
              rootClassName="blog-post-card1-root-class-name10"
              className="lnky-component3"
            ></BlogPostCard1>
          </Link>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

export default Lnky
