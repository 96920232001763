import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Invitation from '../components/invitation'
import Footer from '../components/footer'
import './lnek-drba-a-vzhled-msta.css'

const LnekDrbaAVzhledMsta = (props) => {
  return (
    <div className="lnek-drba-a-vzhled-msta-container">
      <Helmet>
        <title>lnek-drba-a-vzhled-msta - Za Lepší Slatiňany</title>
        <meta
          property="og:title"
          content="lnek-drba-a-vzhled-msta - Za Lepší Slatiňany"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name5"></Header>
      <div className="lnek-drba-a-vzhled-msta-breadcumbs">
        <Link to="/" className="lnek-drba-a-vzhled-msta-navlink">
          <svg viewBox="0 0 1024 1024" className="lnek-drba-a-vzhled-msta-icon">
            <path d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"></path>
          </svg>
        </Link>
        <svg viewBox="0 0 1024 1024" className="lnek-drba-a-vzhled-msta-icon2">
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link to="/clanky" className="lnek-drba-a-vzhled-msta-navlink1">
          <span>Články</span>
          <br></br>
        </Link>
        <svg viewBox="0 0 1024 1024" className="lnek-drba-a-vzhled-msta-icon4">
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link
          to="/clankyudrzba-a-vzhed-mesta"
          className="lnek-drba-a-vzhled-msta-navlink2"
        >
          Údržba a vzhled města
        </Link>
      </div>
      <h1 className="lnek-drba-a-vzhled-msta-text02">Údržba a vzhled města</h1>
      <div className="lnek-drba-a-vzhled-msta-container1">
        <span className="lnek-drba-a-vzhled-msta-text03">
          <span>
            V roce 2018 jsme Vám ve volebním programu předložili naši představu,
            jak chceme pro město pracovat a co všechno bychom ve Slatiňanech
            chtěli zlepšit, či změnit.
          </span>
          <br></br>
          <br></br>
          <span>
            Jedním z našich několika stěžejních bodů byla údržba a vzhled města.
            Bylo a je naší snahou postarat se o některé základní a zásadní
            změny. Jsme totiž přesvědčeni, že vedle nejrůznějších služeb
            zdravotních, sociálních, či sítě obchodů, pohostinství a
            občerstvení, které v našem městě poskytují místní podnikatelé, je
            pro spokojený život občanů velmi důležitý také vzhled města, údržba,
            pořádek a čistota. Nakonec o tom svědčí i řada Vašich připomínek a
            stížností.
          </span>
          <br></br>
          <br></br>
          <span>
            Podařilo se nám obnovit a květinami trochu oživit park před základní
            školou, měli jsme radost, jak nám krásně rozkvetl kruhový objezd,
            tzv. „ šišák“ a přilehlé ostrůvky. Rekonstrukce šišáku proběhla sice
            především kvůli bezpečnosti provozu a z důvodu zamezení neustálých
            oprav vyvrácených obrubníků, přesto jsme ji rádi využili i pro
            květinovou výzdobu.
          </span>
          <br></br>
          <br></br>
          <span>
            A tím se, bohužel, velmi rychle dostávám k tématu, jehož řádky se mi
            nepíší lehce, neboť přes veškerou snahu a opravdu velké a neustálé
            úsilí, se nám v oblasti úklidu a údržby města mnoho nepodařilo.
            Musím přiznat, že jeden z důvodů je i ten, že náš silnější, koaliční
            partner v radě města má na tuto problematiku jiný názor a jak nám
            nedávno při jednání rady sdělil, je se současným stavem spokojen.
          </span>
          <br></br>
          <br></br>
          <span>
            Nás však stále trápí nedostatečná údržba přerostlých keřů či živých
            plotů, které pak brání výhledu při výjezdu např. na hlavní silnici.
            Zametání chodníků a úklid plevele a nánosů na chodnících a pod
            obrubníky je také mnohdy bezútěšný. Sekání travnatých ploch, odvoz
            posekané trávy, která mnohdy na posečených plochách zahnívá a
            zvláště na sídlišti, pod okny bytů není v létě nic příjemného. Chybí
            nám častější, pravidelná a komplexní údržba hřbitova.
          </span>
          <br></br>
          <br></br>
          <span>
            Jsme přesvědčení, že situaci lze změnit velmi rychle a účinně, a to
            změnou systému řízení a organizací práce. Jednoznačně je nutné
            stanovit priority, konkrétní zodpovědnost za jednotlivé úseky,
            časovou náročnost na jednotlivé činnosti a důslednou kontrolu. Po
            dosavadních zkušenostech vážně zvažujeme, na kolik je výhodné
            navyšování počtu zaměstnanců na údržbě či některé činnosti v úklidu,
            sekání trávy a podobné zadat externí firmě, která by určité práce
            vykonávala pravidelně. Rádi bychom alespoň některé změny, které
            povedou ke zlepšení celkového vzhledu a čistoty města, prosadili co
            nejdříve.
          </span>
          <br></br>
        </span>
        <div className="lnek-drba-a-vzhled-msta-container2">
          <div className="lnek-drba-a-vzhled-msta-profile">
            <img
              alt="profile"
              src="http://blur.lepsislatinany.cz/image/DSCF0318.png"
              image_src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&amp;ixlib=rb-1.2.1&amp;h=1200"
              className="lnek-drba-a-vzhled-msta-image"
            />
            <div className="lnek-drba-a-vzhled-msta-container3">
              <span className="lnek-drba-a-vzhled-msta-text21">
                Marie Málková
              </span>
              <span className="lnek-drba-a-vzhled-msta-text22">radní</span>
            </div>
          </div>
        </div>
        <Invitation rootClassName="invitation-root-class-name2"></Invitation>
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default LnekDrbaAVzhledMsta
