import React from 'react'

import PropTypes from 'prop-types'

import './candidates-small-2.css'

const CandidatesSmall2 = (props) => {
  return (
    <div className={`candidates-small-2-container ${props.rootClassName} `}>
      <div className="candidates-small-2-container01">
        <div className="candidates-small-2-container02">
          <span className="candidates-small-2-text">
            <span className="">7.</span>
            <br className=""></br>
          </span>
        </div>
        <span className="candidates-small-2-text03">
          <span className="">Ing. Kamil Urbánek</span>
          <br className=""></br>
        </span>
        <span className="candidates-small-2-text06">
          <span className="">projektant, jednatel společnosti</span>
          <br className=""></br>
          <span className="">47 let</span>
          <br className=""></br>
          <span className="">Bez politické příslušnosti</span>
          <br className=""></br>
        </span>
      </div>
      <div className="candidates-small-2-container03">
        <div className="candidates-small-2-container04">
          <div className="candidates-small-2-container05">
            <span className="candidates-small-2-text13">
              <span className="">8.</span>
              <br className=""></br>
            </span>
          </div>
          <span className="candidates-small-2-text16">
            <span className="">Daniel Dalecký</span>
            <br className=""></br>
          </span>
          <span className="candidates-small-2-text19">
            <span className="">podnikatel v automatizaci</span>
            <br className=""></br>
            <span className="">31 let</span>
            <br className=""></br>
            <span className="">TOP 09</span>
            <br className=""></br>
          </span>
        </div>
      </div>
      <div className="candidates-small-2-container06">
        <div className="candidates-small-2-container07">
          <div className="candidates-small-2-container08">
            <span className="candidates-small-2-text26">
              <span className="">9.</span>
              <br className=""></br>
            </span>
          </div>
          <span className="candidates-small-2-text29">
            <span className="">Ing. Martin Šára</span>
            <br className=""></br>
          </span>
          <span className="candidates-small-2-text32">
            <span className="">pracovník Krajského úřadu</span>
            <br className=""></br>
            <span className="">Pardubického kraje</span>
            <br className=""></br>
            <span className="">56 let, BEZPP</span>
            <br className=""></br>
          </span>
        </div>
      </div>
      <div className="candidates-small-2-container09">
        <div className="candidates-small-2-container10">
          <div className="candidates-small-2-container11">
            <span className="candidates-small-2-text39">
              <span className="">10.</span>
              <br className=""></br>
            </span>
          </div>
          <span className="candidates-small-2-text42">
            <span className="">Petr Nikl</span>
            <br className=""></br>
          </span>
          <span className="candidates-small-2-text45">
            <span className="">číšník</span>
            <br className=""></br>
            <span className="">55 let</span>
            <br className=""></br>
            <span className="">Bez politické příslušnosti</span>
          </span>
        </div>
      </div>
    </div>
  )
}

CandidatesSmall2.defaultProps = {
  rootClassName: '',
}

CandidatesSmall2.propTypes = {
  rootClassName: PropTypes.string,
}

export default CandidatesSmall2
