import React from 'react'

import PropTypes from 'prop-types'

import './candidates-large.css'

const CandidatesLarge = (props) => {
  return (
    <div className={`candidates-large-container ${props.rootClassName} `}>
      <div className="candidates-large-container1">
        <div className="candidates-large-container2">
          <span className="candidates-large-text">
            <span>1.</span>
            <br></br>
          </span>
        </div>
        <span className="candidates-large-text03">
          <span>Ing. Stanislav Šťastný</span>
          <br></br>
        </span>
        <span className="candidates-large-text06">
          <span>ekonom, místostarosta města</span>
          <br></br>
          <span>44 let • ODS</span>
          <br></br>
        </span>
      </div>
      <div className="candidates-large-container3">
        <div className="candidates-large-container4">
          <span className="candidates-large-text11">
            <span>2.</span>
            <br></br>
          </span>
        </div>
        <span className="candidates-large-text14">
          <span>Ing. Vít Steklý</span>
          <br></br>
        </span>
        <span className="candidates-large-text17">
          <span>podnikatel, radní města</span>
          <br></br>
          <span>31 let • TOP 09</span>
          <br></br>
        </span>
      </div>
    </div>
  )
}

CandidatesLarge.defaultProps = {
  rootClassName: '',
}

CandidatesLarge.propTypes = {
  rootClassName: PropTypes.string,
}

export default CandidatesLarge
