import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Invitation from '../components/invitation'
import Footer from '../components/footer'
import './lnek-jak-bude-poplatek-za-odpady-pt-rok.css'

const LnekJakBudePoplatekZaOdpadyPtRok = (props) => {
  return (
    <div className="lnek-jak-bude-poplatek-za-odpady-pt-rok-container">
      <Helmet>
        <title>
          lnek-Jak-bude-poplatek-za-odpady-pt-rok - Za Lepší Slatiňany
        </title>
        <meta
          property="og:title"
          content="lnek-Jak-bude-poplatek-za-odpady-pt-rok - Za Lepší Slatiňany"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name4"></Header>
      <div className="lnek-jak-bude-poplatek-za-odpady-pt-rok-breadcumbs">
        <Link
          to="/"
          className="lnek-jak-bude-poplatek-za-odpady-pt-rok-navlink"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="lnek-jak-bude-poplatek-za-odpady-pt-rok-icon"
          >
            <path d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"></path>
          </svg>
        </Link>
        <svg
          viewBox="0 0 1024 1024"
          className="lnek-jak-bude-poplatek-za-odpady-pt-rok-icon2"
        >
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link
          to="/clanky"
          className="lnek-jak-bude-poplatek-za-odpady-pt-rok-navlink1"
        >
          <span>Články</span>
          <br></br>
        </Link>
        <svg
          viewBox="0 0 1024 1024"
          className="lnek-jak-bude-poplatek-za-odpady-pt-rok-icon4"
        >
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link
          to="/clankyjaky-bude-poplatek-za-odpady-pristi-rok"
          className="lnek-jak-bude-poplatek-za-odpady-pt-rok-navlink2"
        >
          Jaký bude poplatek za odpady příští rok?
        </Link>
      </div>
      <h1 className="lnek-jak-bude-poplatek-za-odpady-pt-rok-text02">
        Jaký bude poplatek za odpady příští rok?
      </h1>
      <div className="lnek-jak-bude-poplatek-za-odpady-pt-rok-container1">
        <span className="lnek-jak-bude-poplatek-za-odpady-pt-rok-text03">
          <span>
            Po našem nástupu na radnici nás v roce 2018 čekal nelehký úkol, jak
            dál posunout odpadové hospodářství a vypořádat se se stále přísnější
            legislativou v souvislosti se skládkováním směsného odpadu. V té
            době se bohužel sešel souběh konce tří smluv, které zajišťovaly svoz
            komunálního odpadu, výsyp tříděných kontejnerů a provoz sběrného
            dvora. Zároveň jsme byli upozorněni krajským úřadem, že uvedené
            služby musíme soutěžit jako jednu zakázku oproti minulosti, což nás
            posunulo do nadlimitního režimu dle zákona. Taková forma veřejné
            soutěže je administrativně daleko náročnější, a tak bylo rozhodnuto
            o výběru firmy, jež tuto administraci městu zprostředkuje. Město na
            tuto administraci obdrželo tři nabídky - OTIDEA CZ s.r.o. (cena 173
            800 Kč), ISES, s.r.o. (192 000 Kč) a MSB Legal, v.o.s. (195 788 Kč).
            Vzhledem k velmi podobným cenám Rada města vybrala společnost MSB
            Legal. Důvodem výběru bylo, že je firma tvořena advokáty, kteří nám
            nabídli smlouvy na míru oproti ostatním, kteří nabízeli pouze úpravu
            vzorových smluv.
          </span>
          <br></br>
          <br></br>
          <span>
            Právě kvalita smluv se projevila v roce 2020, kdy jsme dokázali
            uplatnit u společnosti AVE pokutu ve výši 140 000 Kč. za nedodržení
            smluv. V rámci změny systému odpadového hospodářství jsme byli
            průkopníci v zavedení systému očipování popelnic, který se nyní
            postupně dostává i do jiných měst a obcí. Díky čipování disponujeme
            analytickými daty, např. v četnosti výsypu při jednotlivých svozech,
            a tak Rada města mohla např. efektivně rozhodnout o přesném zahájení
            a ukončení letního týdenního svozu. V současné době dotahujeme
            adresnou evidenci popelnic, která koresponduje s novou legislativou,
            a tak díky čipování budeme připraveni ke spravedlivějšímu poplatku
            za směsný odpad z popelnic.
          </span>
          <br></br>
          <br></br>
          <span>
            Naší snahou bylo též v souvislosti s růstem nákladů na skládkování
            směsného odpadu usnadnit lidem třídění, a to zavedením svozu dům od
            domu u plastů a papíru. Vzhledem ke každoročnímu růstu nákladů za
            skládkování směsného odpadu je naším cílem, aby se občané měli
            možnost svobodně rozhodnout, zda chtějí více třídit a zaplatit na
            poplatcích méně, či nechtějí, ale pak zaplatí více, jelikož se
            nechovají šetrně k životnímu prostředí. Zároveň někteří lidé
            pracují, či studují po většinu času v jiném městě, a přesto platí
            poplatek ve stejné výši, i když většinu odpadu produkují někde
            jinde. Jiní se naopak velmi šetrně chovají k životnímu prostředí a
            produkují minimální směsný odpad, a přesto všichni platíme stejně.
            Právě očipování popelnic tuto kvalitativní změnu umožňuje.
          </span>
          <br></br>
          <br></br>
          <span>
            Naším konečným cílem je nastavit spravedlivý poplatek za skutečnou
            produkci odpadu.
          </span>
          <br></br>
        </span>
        <div className="lnek-jak-bude-poplatek-za-odpady-pt-rok-container2">
          <div className="lnek-jak-bude-poplatek-za-odpady-pt-rok-profile">
            <img
              alt="profile"
              src="http://blur.lepsislatinany.cz/image/steklys.png"
              image_src="http://blur.lepsislatinany.cz/image/steklys.png"
              className="lnek-jak-bude-poplatek-za-odpady-pt-rok-image"
            />
            <div className="lnek-jak-bude-poplatek-za-odpady-pt-rok-container3">
              <span className="lnek-jak-bude-poplatek-za-odpady-pt-rok-text15">
                Ing. Vít Steklý
              </span>
              <span className="lnek-jak-bude-poplatek-za-odpady-pt-rok-text16">
                radní
              </span>
            </div>
          </div>
        </div>
        <Invitation rootClassName="invitation-root-class-name1"></Invitation>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  )
}

export default LnekJakBudePoplatekZaOdpadyPtRok
