import React from 'react'

import PropTypes from 'prop-types'

import './candidates-small.css'

const CandidatesSmall = (props) => {
  return (
    <div className={`candidates-small-container ${props.rootClassName} `}>
      <div className="candidates-small-container01">
        <div className="candidates-small-container02">
          <span className="candidates-small-text">
            <span className="">3.</span>
            <br className=""></br>
          </span>
        </div>
        <span className="candidates-small-text03">
          <span className="">Jana Sobotková</span>
          <br className=""></br>
        </span>
        <span className="candidates-small-text06">
          <span className="">zdravotní sestra specialistka,</span>
          <br className=""></br>
          <span className="">Zdravotnická </span>
          <span className="">záchranná služba</span>
          <br className=""></br>
          <span className="">56 let, BEZPP</span>
          <br className=""></br>
        </span>
      </div>
      <div className="candidates-small-container03">
        <div className="candidates-small-container04">
          <div className="candidates-small-container05">
            <span className="candidates-small-text14">
              <span className="">4.</span>
              <br className=""></br>
            </span>
          </div>
          <span className="candidates-small-text17">
            <span className="">Ing. Marie Herrová</span>
            <br className=""></br>
          </span>
          <span className="candidates-small-text20">
            <span className="">OSVČ, finanční poradkyně</span>
            <br className=""></br>
            <span className="">63 let</span>
            <br className=""></br>
            <span className="">Bez politické příslušnosti</span>
            <br className=""></br>
          </span>
        </div>
      </div>
      <div className="candidates-small-container06">
        <div className="candidates-small-container07">
          <div className="candidates-small-container08">
            <span className="candidates-small-text27">
              <span className="">5.</span>
              <br className=""></br>
            </span>
          </div>
          <span className="candidates-small-text30">
            <span className="">Ing. Aleš Kubát</span>
            <br className=""></br>
          </span>
          <span className="candidates-small-text33">
            <span className="">geodet</span>
            <br className=""></br>
            <span className="">65 let</span>
            <br className=""></br>
            <span className="">Bez politické příslušnosti</span>
            <br className=""></br>
          </span>
        </div>
      </div>
      <div className="candidates-small-container09">
        <div className="candidates-small-container10">
          <div className="candidates-small-container11">
            <span className="candidates-small-text40">
              <span className="">6.</span>
              <br className=""></br>
            </span>
          </div>
          <span className="candidates-small-text43">
            <span className="">Marie Málková</span>
            <br className=""></br>
          </span>
          <span className="candidates-small-text46">
            <span className="">důchodkyně, radní města</span>
            <br className=""></br>
            <span className="">67 let</span>
            <br className=""></br>
            <span className="">Bez politické příslušnosti</span>
            <br className=""></br>
          </span>
        </div>
      </div>
    </div>
  )
}

CandidatesSmall.defaultProps = {
  rootClassName: '',
}

CandidatesSmall.propTypes = {
  rootClassName: PropTypes.string,
}

export default CandidatesSmall
