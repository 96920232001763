import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './program.css'

const Program = (props) => {
  return (
    <div className="program-container">
      <Helmet>
        <title>Program - Za Lepší Slatiňany</title>
        <meta property="og:title" content="Program - Za Lepší Slatiňany" />
      </Helmet>
      <Header rootClassName="header-root-class-name1"></Header>
      <div className="program-container01">
        <div className="program-container02">
          <h1 className="program-text">Programové pilíře</h1>
        </div>
        <div className="program-container03">
          <div className="program-container04">
            <h2 className="program-text001">Odpovědné hospodaření</h2>
            <ul className="program-ul list">
              <li className="program-li list-item">
                <span>
                  <span>Budeme s prostředky daňových poplatníků nadále </span>
                  <span>odpovědně hospodařit</span>
                </span>
              </li>
              <li className="program-li01 list-item">
                <span className="program-text005">
                  <span>Budeme jednat se zámkem o propůjčení Švýcárny </span>
                  <span>
                    s cílem vzájemně propojit expozice a snížit tak její 
                  </span>
                  <span>provozní a mzdové náklady.</span>
                </span>
              </li>
              <li className="program-li02 list-item">
                <span className="program-text009">
                  <span>
                    Chceme pokračovat v získávání investičních dotací 
                  </span>
                  <span>a realizovat energeticky úsporná opatření.</span>
                </span>
              </li>
              <li className="program-li03 list-item">
                <span className="program-text012">
                  <span>
                    Vyčleníme ročně část finančních prostředků v rámci 
                  </span>
                  <span>participativního rozpočtu, kdy občané budou mít </span>
                  <span>možnost přímo rozhodnout, na jaký účel budou</span>
                  <br></br>
                  <span>použity.</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="program-container05">
            <h2 className="program-text018">
              <span>Přívětivá, úsporná </span>
              <span>a dynamická radnice</span>
            </h2>
            <ul className="program-ul01 list">
              <li className="program-li04 list-item">
                <span>
                  <span>
                    Nabízíme starostu, který svou práci bere jako službu 
                  </span>
                  <span>občanům na prvním místě a je jim k dispozici.</span>
                </span>
              </li>
              <li className="program-li05 list-item">
                <span className="program-text024">
                  <span>
                    Jsme zastánci štíhlého úřadu, kde jsou zaměstnanci m
                  </span>
                  <span>otivováni a odměňováni dle odvedené práce.</span>
                </span>
              </li>
              <li className="program-li06 list-item">
                <span className="program-text027">
                  <span>
                    Umožníme občanům vyřizování co nejvíce záležitostí 
                  </span>
                  <span>elektronicky prostřednictvím nových webových </span>
                  <span>stránek, pokud nechtějí a nepotřebují vyřizovat </span>
                  <span>své záležitosti osobně na úřadě.</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="program-container06">
            <h2 className="program-text032">
              <span>Prioritní investice do </span>
              <span>chodníků,  silnic, bezpečnosti </span>
              <span>chodců, cyklistů, odhlučnění </span>
              <span>obchvatu</span>
            </h2>
            <ul className="program-ul02 list">
              <li className="program-li07 list-item">
                <span>
                  <span>
                    Zvýšíme investiční úsilí do rekonstrukcí ulic, oprav 
                  </span>
                  <span>chodníků, silnic, včetně zřízení potřebných </span>
                  <span>
                    přechodů pro chodce dle připraveného  investičního plánu 
                  </span>
                  <span>a koncepce dopravy.</span>
                </span>
              </li>
              <li className="program-li08 list-item">
                <span className="program-text042">
                  <span>
                    Budeme nadále měnit nevhodné lampy svítící  občanům 
                  </span>
                  <span>do oken za úsporná osvětlení.</span>
                </span>
              </li>
              <li className="program-li09 list-item">
                <span className="program-text045">
                  <span>Budeme řešit odhlučnění nové části obchvatu </span>
                  <span>s Ředitelstvím silnic a dálnic.</span>
                </span>
              </li>
              <li className="program-li10 list-item">
                <span className="program-text048">
                  <span>
                    Připravíme projekt na zvýšení kapacity školní družiny 
                  </span>
                  <span>a MŠ s využitím dotace.</span>
                </span>
              </li>
              <li className="program-li11 list-item">
                <span className="program-text051">
                  <span>
                    Budeme nadále projednávat investiční záměry s občany 
                  </span>
                  <span>a společně hledat nejlepší možná řešení.</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="program-container07">
            <h2 className="program-text054">
              <span>Změna v práci údržby, důraz </span>
              <span>na vzhled města a zeleň</span>
            </h2>
            <ul className="program-ul03 list">
              <li className="program-li12 list-item">
                <span>
                  <span>Provedeme reorganizaci údržby za účelem zlepšení </span>
                  <span>
                    jejího řízení, zvýšení pracovního úsilí a vymezení 
                  </span>
                  <span>
                    rajónů a zodpovědnosti za jednotlivé úseky (např. 
                  </span>
                  <span>hřbitov, park před školou, atd.)</span>
                </span>
              </li>
              <li className="program-li13 list-item">
                <span className="program-text062">
                  <span>Chceme pokračovat v obnově zeleně, doplnění </span>
                  <span>
                    laviček a košů a rekonstrukcí autobusových zastávek.
                  </span>
                </span>
              </li>
              <li className="program-li14 list-item">
                <span className="program-text065">
                  <span>Chceme dokončit úpravu parku před ZŠ zejména </span>
                  <span>
                    obměnou mobiliáře (lavičky, koše), zřízení  funkčního 
                  </span>
                  <span>
                    pítka a malé plochy s herními prvky ve spolupráci se ZŠ.
                  </span>
                </span>
              </li>
              <li className="program-li15 list-item">
                <span className="program-text069">
                  <span>
                    Budeme nadále aktivně bránit výstavbě sil ve  Škrovádě 
                  </span>
                  <span>a hledat společně s hřebčínem Kladruby jiné </span>
                  <span>
                    varianty s cílem vymístit areál z rodinné zástavby.
                  </span>
                </span>
              </li>
              <li className="program-li16 list-item">
                <span className="program-text073">
                  <span>Budeme nadále pracovat s novým územním plánem </span>
                  <span>a prosazovat smysluplné změny.</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="program-container08">
            <h2 className="program-text076">
              Rozpracované investice pro každou místní část
            </h2>
            <div className="program-container09">
              <div className="program-container10">
                <h3 className="program-text077">Slatiňany</h3>
                <ul className="program-ul04 list">
                  <li className="program-li17 list-item">
                    <span>
                      Chceme pokračovat v přípravě rekonstrukcí ulic Čechova,
                      Medunova, Nádražní, Tyršova, prostoru sídliště Tyršova s
                      cílem tyto akce postupně realizovat i s pomocí dotací.
                    </span>
                  </li>
                  <li className="program-li18 list-item">
                    <span className="program-text079">
                      Vybereme každoročně další ulice, kde provedeme opravu
                      dlažby chodníku či povrchu silnice.
                    </span>
                  </li>
                  <li className="program-li19 list-item">
                    <span className="program-text080">
                      Zřídíme přechod u Bonetu a další v ulici Vítězství dle
                      schválené koncepce dopravy.
                    </span>
                  </li>
                  <li className="program-li20 list-item">
                    <span className="program-text081">
                      Zrevitalizujeme hřiště na sídlišti s doplněním herních
                      prvků a vytipujeme další vhodné lokality.
                    </span>
                  </li>
                  <li className="program-li21 list-item">
                    <span className="program-text082">
                      Budeme jednat s krajem o projekční přípravě rekonstrukce
                      T.G.M. dle dopravní koncepce
                    </span>
                  </li>
                </ul>
              </div>
              <div className="program-container11">
                <h3 className="program-trpisov">Trpišov</h3>
                <ul className="program-ul05 list">
                  <li className="program-li22 list-item">
                    <span>
                      Budeme nadále spolupracovat s Osadním výborem na etapovité
                      revitalizaci návsi dle zpracované studie, kdy prvním
                      počinem byla instalace herních prvků pro děti.
                    </span>
                  </li>
                  <li className="program-li23 list-item">
                    <span className="program-text084">
                      Připravíme projekt malého energeticky úsporného objektu
                      místo bývalé prodejny pro potřeby hasičů, kulturních a
                      společenských akcí.
                    </span>
                  </li>
                </ul>
                <h3 className="program-kunci">Kunčí</h3>
                <ul className="program-ul06 list">
                  <li className="program-li24 list-item">
                    <span>
                      Dotáhneme do konce odkanalizování Kunčí pomocí dotace dle
                      požadavků legislativy.
                    </span>
                  </li>
                  <li className="program-li25 list-item">
                    <span className="program-text086">
                      Budeme řešit další nutné investice či opravy v úzké
                      součinnosti s Osadním výborem.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="program-container12">
                <h3 className="program-text087">Škrovád</h3>
                <ul className="program-ul07 list">
                  <li className="program-li26 list-item">
                    <span>
                      Dotáhneme chodník od křižovatky T ke křížku včetně
                      osvětlení.
                    </span>
                  </li>
                  <li className="program-li27 list-item">
                    <span className="program-text089">
                      Opravíme povrch silnice od křížku ke klapačce.
                    </span>
                  </li>
                  <li className="program-li28 list-item">
                    <span className="program-text090">
                      Odstraníme světelné koule ve Škrovádské ulici s využitím
                      plánovaného výkopu ČEZu.
                    </span>
                  </li>
                  <li className="program-li29 list-item">
                    <span className="program-text091">
                      Upravíme esteticky a funkčně náves ve Škrovádu, aby
                      nepřipomínala dopravní hřiště a přehlídku kontejnerů.
                    </span>
                  </li>
                  <li className="program-li30 list-item">
                    <span className="program-text092">
                      Zřídíme dětské hřiště ve Škrovádě.
                    </span>
                  </li>
                  <li className="program-li31 list-item">
                    <span className="program-text093">
                      Opravíme špatný stav zvoničky.
                    </span>
                  </li>
                  <li className="program-li32 list-item">
                    <span className="program-text094">
                      Instalujeme úsporné lampy do lokality zástavby rodinných
                      domů za benzinkou.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="program-container13">
            <h2 className="program-text095">
              <span>Převod sportovišť Spartaku </span>
              <span>a jejich investiční obnova</span>
            </h2>
            <ul className="program-ul08 list">
              <li className="program-li33 list-item">
                <span>
                  <span>Dokončíme se Spartakem bezúplatný převod majetku </span>
                  <span>
                    do vlastnictví města a za pomocí dotací přistoupíme 
                  </span>
                  <span>k jejich postupné obnově. </span>
                </span>
              </li>
              <li className="program-li34 list-item">
                <span className="program-text102">
                  <span>
                    Dotáhneme do konce rekonstrukci atletického oválu 
                  </span>
                  <span>a přilehlých sportovišť na nový povrch z tartanu </span>
                  <span>
                    místo škváry s pomocí dotace od Národní sportovní 
                  </span>
                  <span>agentury.</span>
                </span>
              </li>
              <li className="program-li35 list-item">
                <span className="program-text107">
                  <span>Upřednostníme jednoznačně investice do </span>
                  <span>rekonstrukce </span>
                  <span>sportovní haly a plovárny se  zachováním jejího </span>
                  <span>původního rázu před  megaprojektem revitalizace </span>
                  <span>Kabeláčova mlýna.</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="program-container14">
            <h2 className="program-text113">
              <span>Komunální odpad, spravedlivý </span>
              <span>poplatek, nízká daň</span>
            </h2>
            <ul className="program-ul09 list">
              <li className="program-li36 list-item">
                <span>
                  <span>Nepřistoupíme ke zvyšování daně z nemovitosti, </span>
                  <span>kterou může město ovlivnit, v době růstu inflace </span>
                  <span>a životních nákladů rodin.</span>
                </span>
              </li>
              <li className="program-li37 list-item">
                <span className="program-text120">
                  <span>Nastavíme spravedlivější poplatek za komunální </span>
                  <span>odpad podle vyprodukovaného množství.</span>
                </span>
              </li>
              <li className="program-li38 list-item">
                <span className="program-text123">
                  <span>Zavedeme svoz bioodpadu od domu pomocí </span>
                  <span>hnědých popelnic a pravidelně budeme přistavovat </span>
                  <span>mobilní kontejnery v jednotlivých částech města.</span>
                </span>
              </li>
            </ul>
          </div>
          <div className="program-container15">
            <h2 className="program-text127">
              <span>
                Podpora zájmových organizací, spolků, soužití generací 
              </span>
              <span>a cestovní ruch</span>
            </h2>
            <ul className="program-ul10 list">
              <li className="program-li39 list-item">
                <span>
                  Budeme nadále podporovat zájmové organizace a spolky působící
                  na území města formou příspěvků dle jasně nastavených
                  pravidel.
                </span>
              </li>
              <li className="program-li40 list-item">
                <span className="program-text131">
                  Rozšíříme nabídku poskytovanou pečovatelskou službou v případě
                  potřeby seniorů.
                </span>
              </li>
              <li className="program-li41 list-item">
                <span className="program-text132">
                  Opravíme prostory Společenského domu včetně jeho vybavení.
                </span>
              </li>
              <li className="program-li42 list-item">
                <span className="program-text133">
                  Budeme pravidelně komunikovat s občany, živnostníky a
                  podnikateli o rozvoji města.
                </span>
              </li>
              <li className="program-li43 list-item">
                <span className="program-text134">
                  Chceme více spolupracovat s představiteli Zámku a Hřebčína při
                  organizaci akcí a propagaci města.
                </span>
              </li>
              <li className="program-li44 list-item">
                <span className="program-text135">
                  Obnovíme informační a turistické tabule včetně vícejazyčeného
                  textu.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="program-container16">
          <div className="program-container17">
            <span className="program-text136">VOLTE STRANU ČÍSLO 6</span>
            <span className="program-text137">
              Volby do zastupitelstva města Slatiňany
            </span>
            <span className="program-text138">23. - 24. září 2022</span>
          </div>
          <div className="program-container18">
            <img
              alt="image"
              src="http://blur.lepsislatinany.cz/image/logabiele.png"
              className="program-image"
            />
            <img
              alt="image"
              src="http://blur.lepsislatinany.cz/image/logabiele1.png"
              className="program-image1"
            />
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Program
