import React from 'react'

import PropTypes from 'prop-types'

import './one-shot.css'

const OneShot = (props) => {
  return (
    <div className={`one-shot-one-shot ${props.rootClassName} `}>
      <div className="one-shot-container">
        <h2 className="one-shot-text">
          <span className="">Stačí jeden křížek!</span>
          <br className=""></br>
        </h2>
        <span className="one-shot-text3">
          <span className="">
            Aby se vaše volební podpora ODS a TOP 09 koncentrovala, je stěžejní
            zakreslit křížek do čtverečku v záhlaví sloupce před názvem strany
            (poslední sloupec, číslo 6). Tím dáte hlas všem našim kandidátům.
            Volba jednotlivců napříč stranami, podporu pro jednotlivé strany
            naopak oslabuje.
          </span>
          <br className=""></br>
          <br className=""></br>
          <span className="one-shot-text7">
            Silný mandát pro naši kandidátku je volbou silných Slatiňan.
          </span>
          <br className=""></br>
        </span>
      </div>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="one-shot-image"
      />
    </div>
  )
}

OneShot.defaultProps = {
  rootClassName: '',
  image_alt: 'image',
  image_src: 'http://blur.lepsislatinany.cz/image/Frame_1.png',
}

OneShot.propTypes = {
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
}

export default OneShot
