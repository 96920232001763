import React from 'react'

import PropTypes from 'prop-types'

import './candidates-mini.css'

const CandidatesMini = (props) => {
  return (
    <div className={`candidates-mini-candidates-mini ${props.rootClassName} `}>
      <div className="candidates-mini-candidate">
        <div className="candidates-mini-container">
          <span className="candidates-mini-text">
            <span>11.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container01">
          <div className="candidates-mini-container02">
            <span className="candidates-mini-text003">
              <span>Mgr. Jana Zemanová</span>
              <br></br>
            </span>
            <span>
              <span>učitelka</span>
              <br></br>
              <span>56 let</span>
              <br></br>
              <span>Bez politické příslušnosti</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate01">
        <div className="candidates-mini-container03">
          <span className="candidates-mini-text013">
            <span>12.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container04">
          <div className="candidates-mini-container05">
            <span className="candidates-mini-text016">
              <span className="candidates-mini-text017">PharmDr.</span>
              <span> Magda Lukášová</span>
              <br></br>
            </span>
            <span>
              <span>lékárnice</span>
              <br></br>
              <span>69 let</span>
              <br></br>
              <span>ODS</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate02">
        <div className="candidates-mini-container06">
          <span className="candidates-mini-text027">
            <span>13.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container07">
          <div className="candidates-mini-container08">
            <span className="candidates-mini-text030">
              <span>Ing. Eva Dostálová</span>
              <br></br>
            </span>
            <span>
              <span>ekonomka, účetní</span>
              <br></br>
              <span>66 let</span>
              <br></br>
              <span>Bez politické příslušnosti</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate03">
        <div className="candidates-mini-container09">
          <span className="candidates-mini-text040">
            <span>14.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container10">
          <div className="candidates-mini-container11">
            <span className="candidates-mini-text043">
              <span>Jiří Urválek</span>
              <br></br>
            </span>
            <span>
              <span>podnikatel, majitel stavebnin</span>
              <br></br>
              <span>65 let, TOP 09</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate04">
        <div className="candidates-mini-container12">
          <span className="candidates-mini-text051">
            <span>15.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container13">
          <div className="candidates-mini-container14">
            <span className="candidates-mini-text054">
              <span>Jiří Dostálek</span>
              <br></br>
            </span>
            <span>
              <span>OSVČ</span>
              <br></br>
              <span>44 let</span>
              <br></br>
              <span>Bez politické příslušnosti</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate05">
        <div className="candidates-mini-container15">
          <span className="candidates-mini-text064">
            <span>16.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container16">
          <div className="candidates-mini-container17">
            <span className="candidates-mini-text067">
              <span>Ing. Zdeněk Janda</span>
              <br></br>
            </span>
            <span>
              <span>stavební technik, jednatel</span>
              <br></br>
              <span>společnosti</span>
              <br></br>
              <span>56 let, BEZPP</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate06">
        <div className="candidates-mini-container18">
          <span className="candidates-mini-text077">
            <span>17.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container19">
          <div className="candidates-mini-container20">
            <span className="candidates-mini-text080">
              <span>Ing. František Málek</span>
              <br></br>
            </span>
            <span>
              <span>podnikatel</span>
              <br></br>
              <span>27 let</span>
              <br></br>
              <span>Bez politické příslušnosti</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate07">
        <div className="candidates-mini-container21">
          <span className="candidates-mini-text090">
            <span>18.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container22">
          <div className="candidates-mini-container23">
            <span className="candidates-mini-text093">
              <span>Tomáš Velendorf</span>
              <br></br>
            </span>
            <span>
              <span>OSVČ</span>
              <br></br>
              <span>55 let</span>
              <br></br>
              <span>Bez politické příslušnosti</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate08">
        <div className="candidates-mini-container24">
          <span className="candidates-mini-text103">
            <span>19.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container25">
          <div className="candidates-mini-container26">
            <span className="candidates-mini-text106">
              <span>Ing. Dana Štastná</span>
              <br></br>
            </span>
            <span>
              <span>sociální podnikatelka</span>
              <br></br>
              <span>59 let</span>
              <br></br>
              <span>TOP 09</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate09">
        <div className="candidates-mini-container27">
          <span className="candidates-mini-text116">
            <span>20.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container28">
          <div className="candidates-mini-container29">
            <span className="candidates-mini-text119">
              <span>Petr Lukáš</span>
              <br></br>
            </span>
            <span>
              <span>důchodce, technik</span>
              <br></br>
              <span>72 let</span>
              <br></br>
              <span>ODS</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate10">
        <div className="candidates-mini-container30">
          <span className="candidates-mini-text129">
            <span>21.</span>
            <br></br>
          </span>
        </div>
        <div className="candidates-mini-container31">
          <div className="candidates-mini-container32">
            <span className="candidates-mini-text132">
              <span>Mgr. Šárka Steklá</span>
              <br></br>
            </span>
            <span>
              <span>učitelka</span>
              <br></br>
              <span>58 let</span>
              <br></br>
              <span>Bez politické příslušnosti</span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="candidates-mini-candidate11">
        <div className="candidates-mini-container33"></div>
        <div className="candidates-mini-container34">
          <div className="candidates-mini-container35"></div>
        </div>
      </div>
    </div>
  )
}

CandidatesMini.defaultProps = {
  rootClassName: '',
}

CandidatesMini.propTypes = {
  rootClassName: PropTypes.string,
}

export default CandidatesMini
