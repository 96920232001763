import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import CandidatesLargeText from '../components/candidates-large-text'
import CandidatesSmall from '../components/candidates-small'
import CandidatesSmall2 from '../components/candidates-small-2'
import CandidatesMini from '../components/candidates-mini'
import OneShot from '../components/one-shot'
import Footer from '../components/footer'
import './kandidti.css'

const Kandidti = (props) => {
  return (
    <div className="kandidti-container">
      <Helmet>
        <title>Kandidti - Za Lepší Slatiňany</title>
        <meta property="og:title" content="Kandidti - Za Lepší Slatiňany" />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div className="kandidti-breadcumbs">
        <Link to="/" className="kandidti-navlink">
          <svg viewBox="0 0 1024 1024" className="kandidti-icon">
            <path d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"></path>
          </svg>
        </Link>
        <svg viewBox="0 0 1024 1024" className="kandidti-icon2">
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link to="/kandidati" className="kandidti-navlink1">
          <span>Kandidáti</span>
          <br></br>
        </Link>
      </div>
      <h1 className="kandidti-text2">Naši kandidáti</h1>
      <CandidatesLargeText rootClassName="candidates-large-text-root-class-name"></CandidatesLargeText>
      <CandidatesSmall rootClassName="candidates-small-root-class-name2"></CandidatesSmall>
      <CandidatesSmall2 rootClassName="candidates-small2-root-class-name"></CandidatesSmall2>
      <CandidatesMini></CandidatesMini>
      <OneShot rootClassName="one-shot-root-class-name2"></OneShot>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default Kandidti
