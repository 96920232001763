import React from 'react'

import PropTypes from 'prop-types'

import './candidates-large-text.css'

const CandidatesLargeText = (props) => {
  return (
    <div className={`candidates-large-text-container ${props.rootClassName} `}>
      <div className="candidates-large-text-container1">
        <span className="candidates-large-text-text">{props.text}</span>
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="candidates-large-text-image"
        />
      </div>
      <div className="candidates-large-text-container2">
        <div className="candidates-large-text-container3">
          <span className="candidates-large-text-text01">
            <span className="">1.</span>
            <br className=""></br>
          </span>
        </div>
        <span className="candidates-large-text-text04">
          <span className="">Ing. Stanislav Šťastný</span>
          <br className=""></br>
        </span>
        <span className="candidates-large-text-text07">
          <span className="">ekonom, místostarosta města</span>
          <br className=""></br>
          <span className="">44 let • ODS</span>
          <br className=""></br>
        </span>
      </div>
      <div className="candidates-large-text-container4">
        <div className="candidates-large-text-container5">
          <span className="candidates-large-text-text12">
            <span className="">2.</span>
            <br className=""></br>
          </span>
        </div>
        <span className="candidates-large-text-text15">
          <span className="">Ing. Vít Steklý</span>
          <br className=""></br>
        </span>
        <span className="candidates-large-text-text18">
          <span className="">podnikatel, radní města</span>
          <br className=""></br>
          <span className="">31 let • TOP 09</span>
          <br className=""></br>
        </span>
      </div>
    </div>
  )
}

CandidatesLargeText.defaultProps = {
  rootClassName: '',
  text: 'Vsadili jsme na vzdělané mládí a opíráme se o moudrost zkušených.',
  image_src: 'http://blur.lepsislatinany.cz/image/Group_3.png',
  image_alt: 'image',
}

CandidatesLargeText.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
}

export default CandidatesLargeText
