import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Invitation from '../components/invitation'
import Footer from '../components/footer'
import './lnek-rozpotov-odpovdn-hospodaen.css'

const LnekRozpotovOdpovdnHospodaen = (props) => {
  return (
    <div className="lnek-rozpotov-odpovdn-hospodaen-container">
      <Helmet>
        <title>lnek-Rozpotov-odpovdn-hospodaen - Za Lepší Slatiňany</title>
        <meta
          property="og:title"
          content="lnek-Rozpotov-odpovdn-hospodaen - Za Lepší Slatiňany"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name3"></Header>
      <div className="lnek-rozpotov-odpovdn-hospodaen-breadcumbs">
        <Link to="/" className="lnek-rozpotov-odpovdn-hospodaen-navlink">
          <svg
            viewBox="0 0 1024 1024"
            className="lnek-rozpotov-odpovdn-hospodaen-icon"
          >
            <path d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"></path>
          </svg>
        </Link>
        <svg
          viewBox="0 0 1024 1024"
          className="lnek-rozpotov-odpovdn-hospodaen-icon2"
        >
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link to="/clanky" className="lnek-rozpotov-odpovdn-hospodaen-navlink1">
          <span>Články</span>
          <br></br>
        </Link>
        <svg
          viewBox="0 0 1024 1024"
          className="lnek-rozpotov-odpovdn-hospodaen-icon4"
        >
          <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
        </svg>
        <Link
          to="/clankyrozpoctove-odpovedne-hospodareni"
          className="lnek-rozpotov-odpovdn-hospodaen-navlink2"
        >
          Rozpočtově odpovědné hospodaření
        </Link>
      </div>
      <h1 className="lnek-rozpotov-odpovdn-hospodaen-text02">
        Rozpočtově odpovědné hospodaření
      </h1>
      <div className="lnek-rozpotov-odpovdn-hospodaen-container1">
        <span className="lnek-rozpotov-odpovdn-hospodaen-text03">
          <span>
            Za uplynulé 4 roky našeho působení v Zastupitelstvu a Radě města
            jsme v rámci koalice a našich možností prosazovali efektivní
            hospodaření s penězi daňových poplatníků doprovázené mnohdy
            provozními škrty. Zároveň jsme bránili navyšování počtu zaměstnanců
            úřadu. I přes výpadky příjmů ze sdílených daní způsobené pandemií
            byl ve všech letech dosažen kladný hospodářský výsledek a vytvořeny
            zdroje pro další investice. Výsledek hospodaření je znázorněn v
            uvedeném grafu. Za celé volební období město hospodařilo bez nového
            úvěru či půjčky a veškeré investice financovalo z rozpočtu města a
            obdržených dotací.
          </span>
          <br></br>
          <br></br>
          <span>
            Vzhledem k současné složité hospodářské situaci, zejména v oblasti
            růstu cen energií a nákladů ve stavebnictví, budou následující léta
            rozpočtově velmi náročná. Tato situace nutí k přehodnocení
            investičních priorit tak, aby do budoucna podstatně dluhově
            nezatížily město. Týká se to primárně připraveného velkého projektu
            rekonstrukce Kabeláčova mlýna, jehož stavební realizace byla
            odhadnuta v cenách roku 2021 v částce cca 85 mil. Kč. Při současných
            cenách by byla tato investice výrazně vyšší a na dlouhou dobu by
            značně zatížila rozpočet města.
          </span>
          <br></br>
        </span>
        <div className="lnek-rozpotov-odpovdn-hospodaen-container2">
          <span className="lnek-rozpotov-odpovdn-hospodaen-text09">
            Hospodářský výsledek minulých let
          </span>
          <img
            alt="image"
            src="http://blur.lepsislatinany.cz/image/grafik.png"
            className="lnek-rozpotov-odpovdn-hospodaen-image"
          />
          <span>Rozdíl příjmů a výdajů pro daný kalendářní rok.</span>
        </div>
        <span>
          <span>
            Město má aktuálně naléhavější investiční priority, jako je
            rekonstrukce chodníků, silnic, osvětlení, převáděných sportovišť a
            odkanalizování Kunčí. Sportovní hala je ve špatném
            stavebně-technickém stavu a je nutno ji v dohledné době
            zrekonstruovat a také snížit její energetickou náročnost. Sportovní
            halu a přilehlá sportoviště primárně využívá naše základní škola a
            je povinností města, coby zřizovatele, vytvořit příznivé podmínky
            pro povinnou výuku tělesné výchovy a pro sportovní aktivity
            organizované i širší veřejnosti.
          </span>
          <br></br>
          <br></br>
          <span>
            Z výše uvedených důvodů plánovaná rekonstrukce plovárny, jejíž
            studii odsouhlasilo zastupitelstvo města, bude muset před
            naléhavějšími akcemi počkat. I přes tuto skutečnost se budeme snažit
            a jednat s dotčenými orgány o zachování jejího provozu.
          </span>
          <br></br>
          <br></br>
          <span>
            Naše investiční záměry jsou velké, na jejich realizaci chceme využít
            jednak prostředky rozpočtu města a dále prostředky z různých
            dotačních titulů z evropských, národních i krajských zdrojů tak jako
            doposud. Zároveň nedopustíme, aby došlo k prodeji budov ve
            vlastnictví města. Z výše uvedených skutečností vychází náš volební
            program týkajících se investičních priorit s důrazem na odpovědné
            hospodaření.
          </span>
        </span>
        <div className="lnek-rozpotov-odpovdn-hospodaen-container3">
          <div className="lnek-rozpotov-odpovdn-hospodaen-profile">
            <img
              alt="profile"
              src="http://blur.lepsislatinany.cz/image/herrova.png"
              image_src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&amp;ixlib=rb-1.2.1&amp;h=1200"
              className="lnek-rozpotov-odpovdn-hospodaen-image1"
            />
            <div className="lnek-rozpotov-odpovdn-hospodaen-container4">
              <span className="lnek-rozpotov-odpovdn-hospodaen-text19">
                Ing. Marie Herrová
              </span>
              <span className="lnek-rozpotov-odpovdn-hospodaen-text20">
                členka ﬁnančního výboru
              </span>
            </div>
          </div>
        </div>
        <Invitation rootClassName="invitation-root-class-name"></Invitation>
      </div>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

export default LnekRozpotovOdpovdnHospodaen
