import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import LnekDrbaAVzhledMsta from './views/lnek-drba-a-vzhled-msta'
import Home from './views/home'
import Kandidti from './views/kandidti'
import LnekJakBudePoplatekZaOdpadyPtRok from './views/lnek-jak-bude-poplatek-za-odpady-pt-rok'
import LnekRozpotovOdpovdnHospodaen from './views/lnek-rozpotov-odpovdn-hospodaen'
import Program from './views/program'
import Lnky from './views/lnky'

const App = () => {
  return (
    <Router>
      <div>
        <Route
          component={LnekDrbaAVzhledMsta}
          exact
          path="/clankyudrzba-a-vzhed-mesta"
        />
        <Route component={Home} exact path="/" />
        <Route component={Kandidti} exact path="/kandidati" />
        <Route
          component={LnekJakBudePoplatekZaOdpadyPtRok}
          exact
          path="/clankyjaky-bude-poplatek-za-odpady-pristi-rok"
        />
        <Route
          component={LnekRozpotovOdpovdnHospodaen}
          exact
          path="/clankyrozpoctove-odpovedne-hospodareni"
        />
        <Route component={Program} exact path="/program" />
        <Route component={Lnky} exact path="/clanky" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
