import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="footer-image"
      />
      <span className="footer-text">{props.text}</span>
    </footer>
  )
}

Footer.defaultProps = {
  image_src: 'http://blur.lepsislatinany.cz/imageu/logo.png',
  text: 'Zadavatel: koalice ODS a TOP 09 / Zpracovatel: VS Point s.r.o. © 2022',
  rootClassName: '',
  image_alt: 'logo',
}

Footer.propTypes = {
  image_src: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
}

export default Footer
