import React from 'react'

import PropTypes from 'prop-types'

import './blog-post-card1.css'

const BlogPostCard1 = (props) => {
  return (
    <div className={`blog-post-card1-blog-post-card ${props.rootClassName} `}>
      <div className="blog-post-card1-container">
        <span className="blog-post-card1-text">{props.title}</span>
        <span className="blog-post-card1-text1">{props.description}</span>
        <div className="blog-post-card1-container1">
          <div className="blog-post-card1-profile">
            <img
              alt={props.profile_alt}
              src={props.profile_src}
              image_src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&amp;ixlib=rb-1.2.1&amp;h=1200"
              className="blog-post-card1-image"
            />
            <div className="blog-post-card1-container2">
              <span className="blog-post-card1-text2">{props.author}</span>
              <span className="blog-post-card1-text3">{props.position}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BlogPostCard1.defaultProps = {
  rootClassName: '',
  profile_src: 'http://blur.lepsislatinany.cz/image/stastny.png',
  position: 'místostarosta',
  profile_alt: 'profile',
  description:
    'Za uplynulé 4 roky našeho působení v Zastupitelstvu a Radě města jsme v rámci koalice a našich možnos- tí prosazovali efektivní hospodaření s penězi daňových poplatníků dopro- vázené mnohdy provozními škrty. Zá- roveň jsme bránili navyšování počtu zaměstnanců úřadu. I přes výpadky příjmů ze sdílených daní způsobené pandemií byl ve všech letech dosa- žen kladný hospodářský výsledek a vytvořeny zdroje pro další investice. Výsledek hospodaření je znázorněn v uvedeném grafu. Za celé volební ob- dobí město hospodařilo bez nového úvěru či půjčky a veškeré investice fi- nancovalo z rozpočtu města a obdrže- ných dotací....',
  text: 'Celý článek',
  title: 'Rozpočtově odpovědné hospodaření',
  author: 'Ing. Stanislav Šťastný',
}

BlogPostCard1.propTypes = {
  rootClassName: PropTypes.string,
  profile_src: PropTypes.string,
  position: PropTypes.string,
  profile_alt: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
}

export default BlogPostCard1
